.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contest-modal {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50px;
}

.interface-container {
  margin: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.agg-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.agg-container * {
  margin: 4px 0;
}

.centered-text {
  text-align: center;
}

.submit-button {
  display: block;
  margin: 0 auto;
}