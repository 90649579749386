.login-background {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-input {
  background-color: white;
  font-size: 2rem;
}

.login-button {
  border: none;
  margin: 1rem;
  background-color: inherit;
  color: white;
  cursor: pointer;
  font-size: 2rem;
}