.crossings-body {
    display: flex;
    flex-direction: column;
    width: 710px;
    height: 710px;
    margin: 10px;
    justify-content: space-between;
    position: absolute;
    pointer-events: none;
}

.crossings-row {
    display: flex;
    justify-content: space-between;
}

.crossings-div {
    position: relative;
}

.crossings-item {
    border: 1px solid #555;
    border-radius: 50%;
    pointer-events: auto;
}

.crossings-highlight {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 50%;
}

.highlight-none {
    border: 3px dashed transparent;
}

.highlight-ant {
    border: 3px dashed #fa6b61;
}

.highlight-spider {
    border: 3px dashed #59b8ff;
}

.crossings-outer {
    width: 80px;
    height: 80px;
    z-index: 10;
    position: relative;
    margin: 5px;
}

.crossings-inner {
    width: 40px;
    height: 40px;
    z-index: 20;
    top: 18px;
    left: 18px;
    position: absolute;
}

.crossings-item:hover {
    cursor: pointer;
}

.defense-value {
    top: -1px;
    left: -1px;
    position: absolute;
    background-color: rgba(255,255,255,0.5);
    opacity: 0;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    z-index: 30;
    text-align: center;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crossings-outer:hover .defense-value {
    opacity: 1;
}

.player-token {
    height: 90%;
    width: 90%;
}

.cells-body {
    margin: 20px;
    position: absolute;
}

.cells-item {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    border: 1px solid #555;
    width: calc(620px/3);
    height: calc(620px/3);
    margin: -1px 0 0 -1px;
    vertical-align: top;
}

.soothcard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:175px;
    height: 175px;
    border-radius: 50%;
}

.invisible-crossing {
    display: block;
    margin: 0 auto 10px;
}

.card-buttons-div {
    margin-left: 40px;
    vertical-align: top;
}

.card-buttons {
    opacity: 0;
    cursor: auto;
    display: inline-block;
    border: none;
    vertical-align: top;
    background-color: white;
}

.card-buttons-left {
    height: calc(620px/3);
    width: 40px;
}

.card-buttons-left:hover {
    background-image: linear-gradient(to left, gray, 10%, white);
}

.card-buttons-right {
    height: calc(620px/3);
    width: 40px;
}

.card-buttons-right:hover {
    background-image: linear-gradient(to right, gray, 10%, white);
}

.card-buttons-top {
    width: calc(620px/3);
    height: 40px;
}

.card-buttons-top:hover {
    background-image: linear-gradient(to top, gray, 10%, white);
}

.card-buttons-bottom {
    width: calc(620px/3);
    height: 40px;
}

.card-buttons-bottom:hover {
    background-image: linear-gradient(to bottom, gray, 10%, white);
}

#tooltip {
    width: max-content;
    background-color: rgb(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 25px;
    padding: 5px;
    position: fixed;
    z-index: 20;
    cursor: default;
}

#tooltip p {
    margin: 8px 0;
}