.setup-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50px;
}

.page-container {
  width: 50%;
}

.options-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.options-select {
  width: 10rem;
}

.options-text {
  margin: 0;
  padding: .5rem .25rem;
}

.enhanced {
  background-color: skyblue;
}

.buttons-div {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.buttons-div button {
  display: block;
}

.movement-option {
  margin: 2rem;
  display: flex;
  justify-content: center;
}