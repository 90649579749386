.sideboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 70px;
  position: absolute;
  left: calc(700px + 620px/6);
  height: 650px;
  width: 700px;
}

.sideboard-top {
  width: 550px;
}

.invisible-sun-cell {
  display: block;
  display: flex;
  position: relative;
  justify-content: center;
}

.sideboard-sooth {
  position: relative;
  width: 175px;
  height: 175px;
}

.sideboard-bottom {
  display: flex;
  justify-content: space-around;
}

.sideboard-bottom-left {
  width: 200px;
  display: block;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}

.sooth-deck {
  box-sizing: border-box;
  position: absolute;
  top: 25%;
  background-color: white;
  border-radius: 50%;
  height: 175px;
  width: 175px;
}

.drawn-card {
  position: absolute;
  top: 25%;
  display: block;
}

.stage-buttons {
  width: 100%;
  display: flex;
}

.action-button {
  width: 100%;
  color: white;
  font-size: 1.25rem;
  border: transparent;
  border-radius: 25px;
  cursor: pointer;
}

.skip-button {
  width: 100%;
  background-color: white;
  font-size: 1.25rem;
  height: 3rem;
  border: 2px solid;
  border-radius: 25px;
  cursor: pointer;
}

.skip-move {
  font-size: .85rem;
}

.play-log-container {
  display: flex;
  flex-direction: column;
}

.play-log {
  height: 300px;
  width: 350px;
  overflow-y: scroll;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}

.play-log::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}

.play-log::-webkit-scrollbar-track {
  background-color: transparent;
}

.play-log::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0,0,0,0.2);
  background-clip: content-box;
}

.play-log-buffer {
  height: 250px;
}

.export-button {
  width: 7rem;
  font-size: .75rem;
  height: 2rem;
}

.player0log {
  color: #fa6b61;
}

.player1log {
  color: #59b8ff;
}